import React from "react";
import { Switch, Route } from "react-router-dom";

import "./common.css";

import SignIn from "../register/signin";
import Customer from "../customers/customer";
import Invoice from "../invoices/invoices";

const Routers = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/customer" exact component={Customer} />
      <Route path="/invoice" exact component={Invoice} />
    </Switch>
  );
};

const LoginRouter = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
    </Switch>
  );
};

export { LoginRouter, Routers };
