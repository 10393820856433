import React, { useState, useEffect } from "react";
import { Get, GetItemsById } from "../services/invoiceApi";

import { Padding, IsArrayNull, FormatDate } from "../helper";
import { Redirect } from "react-router-dom";
import { IsLoggedIn } from "../session";
import MenuItem from "../common/menuitem";
import Loader from "../common/loader";

import "./invoice.css";

const Invoices = () => {
  /* const [open, setOpen] = useState(false);
  const [calert, setAlert] = useState(false);
  const [orgresult, setOrgResult] = useState(null);
  const [moreFilters, setmoreFilters] = useState(false);
  const [filters, setfilters] = useState({
    username: "",
    teamname: "",
    mobile: "",
    statusid: 0,
  });
  const [edit, setEdit] = useState(false);
  const [copen, setConfirm] = useState(false); */

  const [result, setResult] = useState(null);
  const [invitems, setInvItems] = useState(null);

  const [progress, setProgress] = useState(false);
  const [invoiceid, setInvoiceid] = useState("");
  const [selecteditem, setSelectedItem] = useState(false);

  const getDefaultResults = async () => {
    setProgress(true);
    setSelectedItem(false);
    setInvoiceid("");
    const rslt = await Get();
    if (rslt) setResult(rslt.data);
    //if (rslt) setOrgResult(rslt.data);
    setProgress(false);
  };

  const SelectedItem = async (item) => {
    setProgress(true);
    setSelectedItem(true);
    setInvoiceid(item.invoiceid);
    const rslt = await GetItemsById(item.invoiceid);
    if (rslt) setInvItems(rslt.data);
    setProgress(false);
  };

  useEffect(() => {
    if (IsLoggedIn("userToken")) {
      getDefaultResults();
    }
  }, []);

  const ItemRow = ({ number, item }) => {
    const numberCount = number + 1;
    return (
      <>
        <tr>
          <td
            className="linkitem"
            onClick={() => {
              SelectedItem(item);
            }}
          >
            {Padding(numberCount, 5)}
          </td>
          <td>{item.name}</td>
          <td>{item.mobilenumber}</td>
          <td>{parseFloat(item.totalamt).toFixed(2)}</td>
          <td>{FormatDate(item.createdon)}</td>
          <td className="linkitem">
            <a href={item.file} rel="noopener noreferrer" target="_blank">
              View
            </a>
          </td>
        </tr>
        {selecteditem && item.invoiceid === invoiceid ? (
          <tr>
            <td colSpan="6">
              <table id="invoiceitem">
                <tbody>
                  <tr>
                    <th>Sl. No</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Unit</th>
                    <th>Price</th>
                    <th>Amount</th>
                  </tr>
                  {!IsArrayNull(invitems) ? (
                    invitems.map((item, index) => (
                      <ChildItemRow key={index} number={index} item={item} />
                    ))
                  ) : (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan="10">
                        No Details found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  const ChildItemRow = ({ number, item }) => {
    const numberCount = number + 1;
    return (
      <>
        <tr>
          <td>{Padding(numberCount, 5)}</td>
          <td>{item.name}</td>
          <td>{item.quantity}</td>
          <td>{item.unit}</td>
          <td>{parseFloat(item.price).toFixed(2)}</td>
          <td>{parseFloat(item.amount).toFixed(2)}</td>
        </tr>
      </>
    );
  };

  return (
    <>
      {!IsLoggedIn("userToken") ? (
        <Redirect to="/" />
      ) : (
        <>
          <Loader show={progress} />
          <div className="leftMenu">
            <div className="menuColumn">
              <MenuItem />
            </div>
            <div className="workColumn">
              <main>
                <div className="row">
                  <div className="navlocleft">Invoices</div>
                </div>
                <table id="invoice">
                  <tbody>
                    <tr>
                      <th>Sl. No</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Attachment</th>
                    </tr>
                    {!IsArrayNull(result) ? (
                      result.map((item, index) => (
                        <ItemRow key={index} number={index} item={item} />
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan="10">
                          No Details found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </main>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Invoices;
