import React, { useState, useEffect } from "react";
import { Get } from "../services/customerApi";
import { Padding, IsArrayNull } from "../helper";
import { Redirect } from "react-router-dom";
import { IsLoggedIn } from "../session";
import MenuItem from "../common/menuitem";
import Loader from "../common/loader";

import "./customer.css";

const Customer = () => {
  /* const [open, setOpen] = useState(false);
  const [orgresult, setOrgResult] = useState(null);
  const [edit, setEdit] = useState(false);
  const [calert, setAlert] = useState(false);
  const [moreFilters, setmoreFilters] = useState(false);
  const [alerttype, setAlertType] = useState({
    msg: "",
    danger: false,
  });
  const [selecteditem, setSelectedItem] = useState(false);
  const [filters, setfilters] = useState({
    username: "",
    teamname: "",
    mobile: "",
    statusid: 0,
  });
  const [copen, setConfirm] = useState(false); */

  const [result, setResult] = useState(null);
  const [progress, setProgress] = useState(false);

  const getDefaultResults = async () => {
    setProgress(true);
    const rslt = await Get();
    if (rslt) setResult(rslt.data);
    /* if (rslt) setOrgResult(rslt.data); */
    setProgress(false);
  };

  useEffect(() => {
    if (IsLoggedIn("userToken")) {
      getDefaultResults();
    }
  }, []);

  const ItemRow = ({ number, item }) => {
    const numberCount = number + 1;
    return (
      <>
        <tr>
          <td>{Padding(numberCount, 6)}</td>
          <td>{item.name}</td>
          <td>{item.mobilenumber}</td>
          <td>{item.email}</td>
        </tr>
      </>
    );
  };
  /* const SelectedItem = (item) => {
    setSelectedItem(item);
  };

  const handlefilters = (data, filters) => {
    setfilters(filters);
    setResult(data);
  }; */

  return (
    <>
      {!IsLoggedIn("userToken") ? (
        <Redirect to="/" />
      ) : (
        <>
          <Loader show={progress} />
          <div className="leftMenu">
            <div className="menuColumn">
              <MenuItem />
            </div>
            <div className="workColumn">
              <main>
                <div className="row">
                  <div className="navlocleft">Customers</div>
                </div>
                <table id="agent">
                  <tbody>
                    <tr>
                      <th>Sl. No</th>
                      <th>Customer Name</th>
                      <th>Mobile</th>
                      <th>Email</th>
                    </tr>
                    {!IsArrayNull(result) ? (
                      result.map((item, index) => (
                        <ItemRow key={index} number={index} item={item} />
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan="10">
                          No Details found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </main>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Customer;
